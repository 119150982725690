<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              영업일자
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item check">
                <!-- 포커스 : focus -->
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        name="salesDiv"
                        v-model="searchConditions.salesDiv"
                        value="TOT"
                        @change="onSalesDivChanged"
                      />
                      <i></i>
                      <div class="label">총매출</div>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="item check">
                <!-- 포커스 : focus -->
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        name="salesDiv"
                        v-model="searchConditions.salesDiv"
                        value="NET"
                        @change="onSalesDivChanged"
                      />
                      <i></i>
                      <div class="label">순매출</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              @click.native="onSearchButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">기간별 식음 상품 매출 목록</div>
              <div class="header-caption">[{{ gridDataCount }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li>
                  <erp-button
                      @click.native="onTodaySalesGet"
                      :is-icon-custom="true">
                    당일매출 가져오기
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :is-shortcut-button="true"
                  >인쇄
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="periodGrid"
                :provides="grid"
                :dataSource="gridDataSource"
                :columns="gridColumns"
                :allowExcelExport="true"
                :aggregates="gridAggregates"
                @queryCellInfo="onGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import {
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Resize,
  Page,
} from "@syncfusion/ej2-vue-grids";

import GolfErpAPI from "@/api/v2/GolfErpAPI";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {getFormattedDate} from "@/utils/date";
import {commonCodesGetCommonCodeByIdxAttrb} from "@/utils/commonCodes";
import moment from "moment";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "ProductSalesSummaryTableByPeriod",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    InputDateRange,
    ErpButton
  },
  created() {
    this.inputDateRangeValue.from = getFormattedDate(new Date());
    this.inputDateRangeValue.to = getFormattedDate(new Date());

    this.stores = commonCodesGetCommonCodeByIdxAttrb("STORE_CODE", 2, "FOOD").filter(data => data.useFlag);

    this.gridColumns = [
      {
        field: "bsnDate",
        headerText: "일자",
        width: "80",
        textAlign: "center",
        type: "string"
      },
      {
        headerText: "내장현황",
        columns: [
          {
            field: "visitTeam",
            headerText: "팀수",
            width: "60",
            textAlign: "right",
            type: "number",
            format: "N"
          },
          {
            field: "visitPer",
            headerText: "인원",
            width: "60",
            textAlign: "right",
            type: "number",
            format: "N"
          },
          {
            field: "fitTeamPer",
            headerText: "개인",
            width: "60",
            textAlign: "right",
            type: "number",
            format: "N"
          },
          {
            field: "grpTeamPer",
            headerText: "단체",
            width: "60",
            textAlign: "right",
            type: "number",
            format: "N"
          }
        ]
      }
    ];

    this.gridAggregates = [
      {
        columns: [
          {
            field: "bsnDate",
            aggregationType: "TotalCaption",
            customAggregate: "합계"
          },
          {
            field: "visitTeam",
            aggregationType: "TotalSum",
          },
          {
            field: "visitPer",
            aggregationType: "TotalSum",
          },
          {
            field: "fitTeamPer",
            aggregationType: "TotalSum",
          },
          {
            field: "grpTeamPer",
            aggregationType: "TotalSum",
          }
        ]
      }
    ];

    this.stores.forEach(store => {
      this.gridColumns.push({
        headerText: store.comName,
        columns: [
          {
            field: store.comCode.toLowerCase() + "FoodAmt",
            headerText: "식료",
            width: "90",
            textAlign: "right",
            type: "number",
            format: "N"
          },
          {
            field: store.comCode.toLowerCase() + "DrinkAmt",
            headerText: "음료",
            width: "90",
            textAlign: "right",
            type: "number",
            format: "N"
          },
          {
            field: store.comCode.toLowerCase() + "Amt",
            headerText: "합계",
            width: "90",
            textAlign: "right",
            type: "number",
            format: "N"
          }
        ]
      });

      this.gridAggregates[0].columns.push(
        {
          field: store.comCode.toLowerCase() + "FoodAmt",
          aggregationType: "TotalSum",
        },
        {
          field: store.comCode.toLowerCase() + "DrinkAmt",
          aggregationType: "TotalSum",
        },
        {
          field: store.comCode.toLowerCase() + "Amt",
          aggregationType: "TotalSum",
        }
      );
    });

    this.gridColumns.push(
      {
        headerText: "총매출",
        columns: [
          {
            field: "foodAmt",
            headerText: "식료",
            width: "90",
            textAlign: "right",
            type: "number",
            format: "N"
          },
          {
            field: "drinkAmt",
            headerText: "음료",
            width: "90",
            textAlign: "right",
            type: "number",
            format: "N"
          },
          {
            field: "totalAmt",
            headerText: "합계",
            width: "90",
            textAlign: "right",
            type: "number",
            format: "N"
          }
        ]
      },
      {
        field: "guestUnitPrice",
        headerText: "객단가",
        width: "70",
        textAlign: "right",
        type: "number",
        format: "N"
      },
      {
        field: "cumulativeGuestUnitPrice",
        headerText: "누계객단가",
        width: "90",
        textAlign: "right",
        type: "number",
        format: "N"
      }
    );

    this.gridAggregates[0].columns.push(
      {
        field: "foodAmt",
        aggregationType: "TotalSum",
      },
      {
        field: "drinkAmt",
        aggregationType: "TotalSum",
      },
      {
        field: "totalAmt",
        aggregationType: "TotalSum",
      },
      {
        field: "guestUnitPrice",
        aggregationType: "TotalAvg",
      }
    );
  },
  mounted() {},
  data() {
    return {
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey, Page],
      inputDateRangeValue: { to: null, from: null },
      searchConditions: {
        salesDiv: "TOT"
      },
      gridDataCount: 0,
      gridDataSource: [],
      stores: [],
      gridColumns: [],
      gridAggregates: []
    };
  },
  computed: {},
  methods: {
    async onSearchButtonClicked() {
      const data = await GolfErpAPI.fetchProductSalesSummaryTableByPeriod({
        bsnDateFrom: this.inputDateRangeValue.from,
        bsnDateTo: this.inputDateRangeValue.to
      });

      let gridData = [];
      let sumGuestUnitPrice = 0;
      let rowCount = 0;
      data.forEach(data => {
        let storeData = {};
        this.stores.forEach(store => {
          const foodFieldName = store.comCode.toLowerCase() + "FoodAmt";
          const foodDataName = store.comCode.toLowerCase() + (this.searchConditions.salesDiv === "NET" ? "FoodNetAmt" : "FoodTotAmt");
          const drinkFieldName = store.comCode.toLowerCase() + "DrinkAmt";
          const drinkDataName = store.comCode.toLowerCase() + (this.searchConditions.salesDiv === "NET" ? "DrinkNetAmt" : "DrinkTotAmt");
          const totFieldName = store.comCode.toLowerCase() + "Amt";
          const totDataName = store.comCode.toLowerCase() + (this.searchConditions.salesDiv === "NET" ? "NetAmt" : "TotAmt");

          storeData[foodFieldName] = data[foodDataName];
          storeData[drinkFieldName] = data[drinkDataName];
          storeData[totFieldName] = data[totDataName];
        });

        rowCount += 1;
        sumGuestUnitPrice += data.visitPer ? Math.round(data[this.searchConditions.salesDiv === "NET" ? "netAmt" : "totAmt"] / data.visitPer) : 0;

        gridData.push({
          ...data,
          ...storeData,
          foodAmt: data[this.searchConditions.salesDiv === "NET" ? "foodNetAmt" : "foodTotAmt"],
          drinkAmt: data[this.searchConditions.salesDiv === "NET" ? "drinkNetAmt" : "drinkTotAmt"],
          totalAmt: data[this.searchConditions.salesDiv === "NET" ? "netAmt" : "totAmt"],
          guestUnitPrice: data.visitPer ? Math.round(data[this.searchConditions.salesDiv === "NET" ? "netAmt" : "totAmt"] / data.visitPer) : 0,
          cumulativeGuestUnitPrice: Math.round(sumGuestUnitPrice / rowCount)
        });
      });

      this.gridDataSource = gridData;
      this.gridDataCount = gridData.length;
    },
    excel() {
      this.$refs.periodGrid.excelExport();
    },
    print() {
      alert("인쇄 개발 예정");
    },
    onSalesDivChanged() {
      this.onSearchButtonClicked();
      // let gridData = [];
      // this.gridDataSource.forEach(data => {
      //   let storeData = {};
      //   this.stores.forEach(store => {
      //     const foodFieldName = store.comCode.toLowerCase() + "FoodAmt";
      //     const foodDataName = store.comCode.toLowerCase() + (this.searchConditions.salesDiv === "NET" ? "FoodNetAmt" : "FoodTotAmt");
      //     const drinkFieldName = store.comCode.toLowerCase() + "DrinkAmt";
      //     const drinkDataName = store.comCode.toLowerCase() + (this.searchConditions.salesDiv === "NET" ? "DrinkNetAmt" : "DrinkTotAmt");
      //     const totFieldName = store.comCode.toLowerCase() + "Amt";
      //     const totDataName = store.comCode.toLowerCase() + (this.searchConditions.salesDiv === "NET" ? "NetAmt" : "TotAmt");
      //
      //     storeData[foodFieldName] = data[foodDataName];
      //     storeData[drinkFieldName] = data[drinkDataName];
      //     storeData[totFieldName] = data[totDataName];
      //   });
      //
      //   gridData.push({
      //     ...data,
      //     ...storeData,
      //     foodAmt: data[this.searchConditions.salesDiv === "NET" ? "foodNetAmt" : "foodTotAmt"],
      //     drinkAmt: data[this.searchConditions.salesDiv === "NET" ? "drinkNetAmt" : "drinkTotAmt"],
      //     totalAmt: data[this.searchConditions.salesDiv === "NET" ? "netAmt" : "totAmt"]
      //   });
      // });
      //
      // this.gridDataSource = gridData;
    },
    onGridQueryCellInfo(args) {
      const {
        column: {
          field
        },
        data
      } = args;

      let storeFields = [];
      this.stores.map(store => {
        storeFields.push(store.comCode.toLowerCase() + "FoodAmt");
        storeFields.push(store.comCode.toLowerCase() + "DrinkAmt");
        storeFields.push(store.comCode.toLowerCase() + "Amt");
      });

      if (
        field === "visitTeam" ||
        field === "visitPer" ||
        field === "fitTeamPer" ||
        field === "grpTeamPer" ||
        field === "foodAmt" ||
        field === "drinkAmt" ||
        field === "totalAmt" ||
        field === "guestUnitPrice" ||
        field === "cumulativeGuestUnitPrice" ||
        storeFields.findIndex(data => data === field) >= 0
      ) {
        if (data[field] === 0) {
          args.cell.innerText = "-";
        }
      }
    },
    async onTodaySalesGet() {
      const bsnDate = moment().format("YYYY-MM-DD");

      await GolfErpAPI.fetchCloseCheck({
        bsnDateFrom: bsnDate,
        bsnDateTo: bsnDate,
        storeCode: "FRONT",
      });

      const request = [
        {
          name: "SP_CLOSE_PART_DIV",
          inParams: [bsnDate.replaceAll("-", "")]
        },
        {
          name: "SP_CLOSE_SALES_CLASS",
          inParams: [bsnDate.replaceAll("-", "")]
        }
      ];

      await GolfErpAPI.putStoredProcedureExecute({
        storedProcedureInfos: request,
      });
    }
  }
};
</script>
